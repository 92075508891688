import shopifyGenericDefaults from '../shopify-ella-generic/config.js';

const addWishListItem = () => {
  document.querySelectorAll('.cm_SearchResult .wishlist-hero-custom-button').forEach((el) => {
    const id = el.getAttribute('data-wlh-id');
    const title = el.getAttribute('data-wlh-name');
    const price = el.getAttribute('data-wlh-price');
    const link = el.getAttribute('data-wlh-link');
    const variantId = el.getAttribute('data-wlh-variantid');
    const image = el.getAttribute('data-wlh-image');

    const productButtonInfo = {
      ButtonClassElement: `cm_wishlist-id--${id}`,
      ProductId: +id,
      ProductTitle: title,
      ProductPrice: +price,
      ProductLink: link,
      ProductVariantId: +variantId,
      ProductImage: image,
      ButtonMode: 'icon_only',
      WishlistHash: '',
    };

    window.WishListHero_SDK?.InitializeAddToWishListButton?.(productButtonInfo);
  });
};

const baseUpdateCallback = shopifyGenericDefaults.Widgets.find(
  (w) => w.name === 'SearchResult',
).updateCallback;

const updateCallback = () => {
  baseUpdateCallback?.();

  // Hero wishlist app docs: https://d2uzwr8l81w3zz.cloudfront.net/InitializeAddToWishListItem.html
  addWishListItem();
};

export default {
  includes: ['shopify-ella-generic'],
  ...shopifyGenericDefaults,
  pagination: {
    edgeRange: 1,
    centralRange: 2,
  },
  fitmentSearch: {
    ...shopifyGenericDefaults.fitmentSearch,
    categorySelectionPages: [
      ...shopifyGenericDefaults.fitmentSearch.categorySelectionPages,
      {
        pathname: window.document.querySelector('#cm_brands-page') ? window.location.pathname : null,
        field: 'vendor',
      },
    ],
  },
  Widgets: [
    ...shopifyGenericDefaults.Widgets.filter(
      (w) =>
        !['Garage', 'SearchBoxDialogButton', 'FacetBrandTiles', 'SearchResult', 'RelatedItems'].includes(
          w.name,
        ),
    ),
    {
      name: 'Garage',
      location: { selector: '#cm-garage', class: 'cm_garage__container' },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'SearchBoxDialogButton',
      location: {
        selector: '.header-mobile .search-box',
        class: 'cm-mobile-search-box',
      },
      template: 'searchBox/dialogButtonAdaptable',
      visibleIf: () => window.Convermax?.config?.isSearchBoxEnabled,
    },
    {
      name: 'FacetBrandTiles',
      type: 'FacetTiles',
      location: '#cm_brands-page',
      template: 'facetTiles/alphabeticalContainer',
      view: 'grid',
      isVehicleWidgetDisabled: !!window.document.querySelector('#cm-ymm'),
    },
    {
      name: 'SearchResult',
      infiniteScrollDisabled: !!window.Convermax.config?.infiniteScrollDisabled,
      updateCallback,
    },
    {
      name: 'RelatedItems',
      location: '#cm-related-products',
      template: 'product/relatedItems',
      count: window.Convermax.config?.relatedItemsCount || 4,
      useManualItems: true,
    },
  ],
};
